<template>
    <div ref="sidebar" class="app-left-sidebar">
        <div
            class="als--menu"
            :class="{ hovered: hovered || Boolean(selectedItem), active: Boolean(selectedItem) }"
            @mouseover="onMouseOver"
            @mouseout="onMouseOut"
        >
            <div class="als--selector">
                <NuxtLink to="/" class="als--selector--top" title="go to main page">
                    <svg class="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 39" width="149" height="39">
                        <g fill="none" stroke-width="2px" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="currentColor" d="M2,2h18c6,0,10,5,10,10s-4,10-10,10H2" />
                            <g stroke="currentColor">
                                <line class="logo-1" x1="21" y1="27.09" x2="28" y2="37" />
                                <path
                                    class="logo-2"
                                    d="M42.37,27.16l9.83-6.88c-2.87-4.1-9.01-5.9-13.93-2.46-4.91,3.44-5.32,9.83-2.46,13.93,3.44,4.91,9.83,5.32,13.93,2.46.95-.67,1.78-1.51,2.44-2.47"
                                />
                                <path class="logo-f" d="M61,36v-20c0-5,4-10,10-10" />
                                <line class="logo-f" x1="57" y1="17" x2="69" y2="17" />
                                <path
                                    class="logo-e"
                                    d="M80.34,27.16l9.83-6.88c-2.87-4.1-9.01-5.9-13.93-2.46-4.91,3.44-5.32,9.83-2.46,13.93,3.44,4.91,9.83,5.32,13.93,2.46.95-.67,1.78-1.51,2.44-2.47"
                                />

                                <path class="logo-r" d="M97,36v-10c0-5,4-10,10-10" />
                                <path class="logo-u" d="M113,16v12c0,4,3,8,8,8s8-4,8-8v-12" />
                                <path
                                    class="logo-s"
                                    d="M146,20.5c0-2.5-2-4.5-5-4.5s-5,2-5,4.5c0,6,11,3,11,10,0,3.5-3,5.5-6,5.5s-6-2-6-5.5"
                                />
                            </g>
                        </g>
                    </svg>
                </NuxtLink>

                <ul class="als--selector--mid">
                    <li
                        v-for="(item, name) in topMenu"
                        :key="name"
                        class="als--selector--key"
                        :class="{ active: selectedTagName === name }"
                        @click="selectMenu(name)"
                    >
                        <RIcon v-if="item.icon" :width="24" :name="item.icon" class="als--50-wrap" />
                        <component :is="item.component" v-if="item.component" class="als--50-wrap" />
                        <span class="als--text">{{ item.name }}</span>
                    </li>
                </ul>
                <ul class="als--selector--bottom">
                    <li
                        v-for="(item, name) in bottomMenu"
                        :key="name"
                        class="als--selector--key"
                        :class="{ active: selectedTagName === name }"
                        @click="selectMenu(name)"
                    >
                        <RIcon v-if="item.icon" :width="24" :name="item.icon" class="als--50-wrap" />
                        <component :is="item.component" v-if="item.component" class="als--50-wrap" />
                        <span class="als--text">{{ item.name }}</span>
                    </li>
                </ul>
            </div>

            <component :is="selectedItem" class="als--main-field" @click="onCompClick" />

            <div v-if="selectedItem" class="als--close-key" title="menu close" @click="doHide">
                <RIcon
                    name="cancel"
                    :width="24"
                    color="white"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useTranslation } from 'i18next-vue'
import type { Component } from 'vue'
import LangIcon from './Languages/LangIcon.vue'

/**
 * Types
 */
export interface MenuItem {
    icon?: AppIcon
    name: string
    component?: Component
}

type Menu = Record<string, MenuItem>

/**
 * Use
 */
const route = useRoute()
const router = useRouter()
const { t } = useTranslation()

/**
 * Data
 */
const selectedItem = ref<Component | null>(null)
const selectedTagName = ref<string | null>(null)
const hovered = ref(false)

/**
 * Local
 */
// флаг, чтобы не сработал watch на изменение хеша
let trace: boolean = false

/**
 * Provide
 */
provide('onLinkClick', onLinkClick)

/**
 * $Refs
 */
const sidebar = ref<HTMLElement | null>(null)

/**
 * Computed
 */
const topMenu = computed(() => {
    return {
        GlobalSearch: { icon: 'search', name: t('@globalSearch') },
        MainMenu: { icon: 'information', name: t('@menuMain') },
        ReferencesMenu: { icon: 'doc', name: t('@menuReferences') },
    } as Menu
})

const bottomMenu = computed(() => {
    return {
        Language: { component: LangIcon, name: t('@language') },
        Settings: { icon: 'gear', name: t('@settings') },
        User: { icon: 'man', name: t('@user') },
    } as Menu
})

/**
 * Hooks
 */
onMounted(() => {
    document.addEventListener('keydown', onKeydown)
    document.addEventListener('click', onClick)
})

onUnmounted(() => {
    document.removeEventListener('keydown', onKeydown)
    document.removeEventListener('click', onClick)
})

/**
 * Watch
 */
watch(() => route.path, () => {
    doHide()
})
watch(() => route.fullPath, () => {
    if (trace) {
        return
    }
    showComponentByPath()
})

/**
 * Methods
 */
function onKeydown(event: KeyboardEvent) {
    if (selectedItem.value && event.key === 'Escape') {
        doHide()
    }
}

function onClick(event: MouseEvent) {
    if (!selectedItem.value) {
        return
    }
    const target = event.target as HTMLElement
    if (parents(target).every(i => i !== sidebar.value)) {
        doHide()
    }
}

async function selectMenu(menuName: string) {
    // noinspection TypeScriptCheckImport
    const comp = await import(`./MenuItems/${menuName}.vue`) as any
    selectedItem.value = markRaw(comp.default)
    selectedTagName.value = menuName
}

async function doHide() {
    if (!trace && selectedTagName.value) {
        await patchRouter()
    }
    selectedItem.value = null
    selectedTagName.value = null
}

function onMouseOver() {
    hovered.value = true
}

function onMouseOut() {
    hovered.value = false
}

async function onLinkClick(event: MouseEvent) {
    const el = event.target as HTMLAnchorElement
    const path = el.getAttribute('href')
    if (!path) {
        return
    }

    await patchRouter(path)
    await doHide()
}

async function patchRouter(path?: string) {
    trace = true
    await router.push({ path, hash: `#menu:${selectedTagName.value}` })
    await router.push({ path, hash: '' })
    trace = false
}

function onCompClick(event: MouseEvent) {
    if (!selectedItem.value || event.which !== 1) {
        return false
    }

    // закрыть sidebar по клику на ссылке ведущую на текущую страницу
    let el = event.target as HTMLElement | null
    if (!el) {
        return false
    }
    if (el.tagName !== 'A') {
        parents(el).some(p => {
            if (p.tagName !== 'A') {
                return false
            }
            el = p
            return true
        })
    }
    if (!el.getAttribute) {
        return false
    }

    const elPath = el.getAttribute('href')
    const path = useRoute().path

    if (path === elPath) {
        onLinkClick(event)
        doHide()
    }
}

function showComponentByPath() {
    const [_path, componentName] = route.hash.split('#menu:')
    if (!componentName || componentName === selectedTagName.value) {
        doHide()
        return
    }
    selectMenu(componentName)
}
</script>
