<template>
    <div class="flex flex-col lang-icon">
        <span
            class="roboto-mono fs-16"
            :title="`${$t('@interface')}: ${langIndex[langUI].nameEn} (${langIndex[langUI].name})`"
        >{{
            langUI.toUpperCase() }}</span>
        <template v-if="langUI !== langData">
            <hr>
            <span
                class="roboto-mono fs-16"
                :title="`${$t('@data')}: ${langIndex[langData].nameEn} (${langIndex[langData].name})`"
            >{{
                langData.toUpperCase() }}</span>
        </template>
    </div>
</template>

<script setup lang="ts">
import { type AppLang, useAppStore } from '#imports'

const appStore = useAppStore()
const langIndex: Record<string, AppLang> = (await appStore.getLanguages()).reduce((acc, i) => ({ ...acc, [i.code]: i }), {})
const langUI = computed(() => appStore.langUI)
const langData = computed(() => appStore.langData)
</script>

<style lang="scss">
.lang-icon {
    hr {
        width: 26px;
        border-color: var(--app-color);
        border: 1px solid;
    }
}
</style>
